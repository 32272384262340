import * as Msal from 'msal'
import { msalConfig, loginRequest} from '../adalConfig'
const myMSALObj = new Msal.UserAgentApplication(msalConfig)

export const signInAction = () => {
    return dispatch => {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");
        const msie11 = ua.indexOf("Trident/");
        const msedge = ua.indexOf("Edge/");
        const isIE = msie > 0 || msie11 > 0;
        const isEdge = msedge > 0;

        let signInType = isIE ? "Redirect" : "Popup"
        if(signInType === "Popup") {
            myMSALObj.loginPopup(loginRequest)
            .then(LoginResponse => {
                dispatch(success(myMSALObj))
            })
            .catch(function (error) {
                dispatch(failure(error))
            })
        } else {
            myMSALObj.loginRedirect(loginRequest)
            .then(LoginResponse => {
                dispatch(success(myMSALObj))
            })
            .catch(function (error) {
                dispatch(failure(error))
            })
        }
    }

    function success(myMSALObj) {
        let userData = myMSALObj.getAccount()
        return {
            type: 'SIGNIN_SUCCESS',
            userData
        }
    }

    function failure(error) {
        return {
            type: 'SIGNIN_ERROR',
            error
        }
    }
}

export const signOutAction = (MSALObj) => {
    return dispatch => {
        MSALObj.logout()
    }
}