import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { getCourses } from '../Actions/courseActions'

import authentication from 'react-azure-b2c'
import * as Msal from 'msal'
import { msalConfig, loginRequest} from '../adalConfig'

const myMSALObj = new Msal.UserAgentApplication(msalConfig)

class Courses extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modules: '',
            learningPaths: '',
            roles: '',
            levels: '',
            products: '',
            selectedTypes : ['module','learningPath'],
            count: -1
        }

    }

    componentDidMount() {
        if(myMSALObj.getAccount()) {
            this.setState({
                login: true,
                userData: myMSALObj.getAccount()
            })
            this.props.getCourses()
        }
      }

    componentWillReceiveProps = (props) => {
        const { modules, learningPaths, roles, levels, products } = props.courses
        if(myMSALObj.getAccount()) {
            this.setState({
                login: true,
                userData: myMSALObj.getAccount()
            })
        }
        
        let selectedRoles= [], role, roleCount= 0
        if(roles.length > 0) {
            for(role in roles) {
                selectedRoles[roleCount++] = roles[role]['id']
            }
        }

        let selectedLevels= [], level, levelCount= 0
        if(levels.length > 0) {
            for(level in levels) {
                selectedLevels[levelCount++] = levels[level]['id']
            }
        }

        let selectedProducts = [],product, productcount=0, productChild, child
        if(products.length > 0) {       
            for(product in products) {
                selectedProducts[productcount++] = products[product]['id']
                if(products[product]['children']) {
                    productChild = products[product]['children']
                    if(productChild.length > 0) {
                        for(child in productChild) {
                            selectedProducts[productcount++] = productChild[child]['id']
                        }
                    }
                }
            }
        }

        this.setState({
            modules: modules,
            learningPaths: learningPaths,
            roles: roles,
            levels: levels,
            products: products,
            selectedRoles: selectedRoles,
            selectedLevels: selectedLevels,
            selectedProducts: selectedProducts,
            showAllRoles: selectedRoles,
            showAllLevels: selectedLevels,
            showAllProducts: selectedProducts,
            count: 0
        })
    }

    levelChange = () => {
        var items = document.getElementsByName('levels')
        var selectedLevels=[], j=0
        for(var i=0; i<items.length; i++) {
            if(items[i].type==='checkbox' && items[i].checked===true) {
                selectedLevels[j] = items[i].value
                j++
            }
        }
        if(selectedLevels.length === 0)
            selectedLevels = this.state.showAllLevels
        
        this.setState({
            ...this.state,
            selectedLevels : selectedLevels
        })
    }

    roleChange = () => {
        var items = document.getElementsByName('role')
        var selectedRoles=[], j=0
        for(var i=0; i<items.length; i++) {
            if(items[i].type==='checkbox' && items[i].checked===true) {
                selectedRoles[j] = items[i].value
                j++
            }
        }
        if(selectedRoles.length === 0)
            selectedRoles = this.state.showAllRoles

        this.setState({
            ...this.state,
            selectedRoles : selectedRoles
        })
    }

    typeChange = () => {
        var items = document.getElementsByName('type')
        var selectedTypes=[], j=0
        for(var i=0; i<items.length; i++) {
            if(items[i].type==='checkbox' && items[i].checked===true) {
                selectedTypes[j] = items[i].value
                j++
            }
        }
        if(selectedTypes.length === 0)
            selectedTypes = ['module','learningPath']
        
            this.setState({
                ...this.state,
                selectedTypes : selectedTypes
            })
    }

    productChange = () => {
        console.log("ALL PRODUCT", this.state.showAllProducts)
        var items = document.getElementsByName('product')
        var selectedProducts=[], j=0
        for(var i=0; i< items.length; i++) {
            if(items[i].type==='checkbox' && items[i].checked === true) {
                selectedProducts[j++] = items[i].value
            }
        }
        console.log("LEN", selectedProducts.length)
        console.log("ALL PRODUCT", this.state.showAllProducts)
        if(selectedProducts.length === 0){
            selectedProducts = this.state.showAllProducts
        }
        console.log("CHANGE", selectedProducts)
        this.setState({
            ...this.state,
            selectedProducts: selectedProducts
        })
    }

    showAll = () => {
        var itemLevel = document.getElementsByName('levels')
        for(var i=0; i< itemLevel.length; i++) {
            if(itemLevel[i].type==='checkbox' && itemLevel[i].checked === true)
                itemLevel[i].checked = false
        }
        var itemRole = document.getElementsByName('role')
        for(var j=0; j< itemRole.length; j++) {
            if(itemRole[j].type==='checkbox' && itemRole[j].checked === true) 
                itemRole[j].checked = false
        }
        var itemType = document.getElementsByName('type')
        for(var k=0; k< itemType.length; k++) {
            if(itemType[k].type==='checkbox' && itemType[k].checked === true)
                itemType[k].checked = false
        }
        var itemProduct = document.getElementsByName('product')
        for(var l=0; l< itemProduct; l++) {
            if(itemProduct[l].type==='checkbox' && itemProduct[l].checked === true)
                itemProduct[l].checked = false
        }
        this.setState({
            selectedLevels : this.state.showAllLevels,
            selectedRoles : this.state.showAllRoles,
            selectedTypes : ['module','learningPath'],
            selectedProducts : this.state.showAllProducts
        })
    }

    flipClick = (e) => {
        var eid = "Card_" + e.target.id
        var item = document.getElementById(eid)
        item.classList.add('flipped')
    }

    flipRevers = (e) => {
        var eid = "Card_" + e.target.id
        var item = document.getElementById(eid)
        item.classList.remove('flipped')
    }

    render() {
        let dispmodule = [], i=0, count = 0;
        const { modules, learningPaths, selectedLevels, selectedRoles, selectedTypes, selectedProducts } = this.state
        let isModule = ['module'].some(r => selectedTypes.includes(r))
        if(isModule) {
            for(i in modules) {
                var num = modules[i]['duration_in_minutes'];
                var hours = (num / 60);
                var rhours = Math.floor(hours);
                var minutes = (hours - rhours) * 60;
                var rminutes = Math.round(minutes);

                const foundLevel = modules[i]['levels'].some(r => selectedLevels.includes(r))
                const foundRole = modules[i]['roles'].some(r => selectedRoles.includes(r))
                const foundProduct = modules[i]['products'].some(r => selectedProducts.includes(r))

                var levelCount = modules[i]['levels'].length
                var roleCount = modules[i]['roles'].length
                var dispLevelstags = [], disprolestags = []
                for(var countl=0; countl < levelCount; countl++) {
                    dispLevelstags.push(
                        <li className="tags" key={countl}>{modules[i]['levels'][countl]}</li>
                    )
                }
                for(var countr=0; countr < roleCount; countr++) {
                    disprolestags.push(
                        <li className="tags" key={countr}>{modules[i]['roles'][countr]}</li>
                    )
                }

                if( foundLevel===true && foundRole===true && foundProduct===true){
                    var Cid = "Card_" + modules[i]['uid']
                    var id = modules[i]['uid']
                    count++
                    dispmodule.push( 
                        <div className="card module-card" key={i} style={{maxHeight: '345px', minHeight: '345px', marginTop: '2%'}}>
                            <div className="flipM">
                                <div className="cardM" id={Cid}>
                                    <div className="faceM frontM" id={id}>
                                        <div className="top-bg">
                                            <div className="module-img">
                                                <img src={modules[i]['icon_url']} alt="" />
                                            </div>
                                        </div>
                                        <div className="module-inner">
                                            <p>{modules[i]['type']}</p>
                                            <h3 style={{minHeight: '45px'}}>
                                                <a href={modules[i]['url']} target="_blank">{modules[i]['title']}</a>
                                            </h3>
                                            <div className="rating-part">
                                                <span className="tot-timing">
                                                    {rhours > 0 ? <div>{rhours} Hours {rminutes} Minutes</div> : <div>{rminutes} Minutes</div>}
                                                </span>
                                            </div>
                                            <ul className="tag-grp">
                                                <li className="tags">{modules[i]['products'][0]}</li>
                                                <li className="tags">{modules[i]['roles'][0]}</li>
                                                <li className="tags">{modules[i]['levels'][0]}</li>
                                            </ul>
                                        </div>
                                        <div className="module-bottom">
                                            <div className="card-detail">
                                                <p dangerouslySetInnerHTML = {{__html: modules[i]['summary']}} style={{maxHeight: '47px', minHeight: '47px', overflow: 'hidden'}}></p>
                                            </div>
                                            <div className="cardReadMore"><h4 id={id} onClick={(id) => this.flipClick(id)} style={{cursor: 'pointer', marginBottom: '10px'}}>Read More...</h4></div>
                                        </div>
                                    </div>
                                    <div className="faceM backM" id={id}> 
                                        <div className="top-bg">
                                            <div className="module-img">
                                                <img src={modules[i]['icon_url']} alt="" />
                                            </div>
                                        </div>
                                        <div className="module-inner">
                                            <div className="rating-part">
                                                <span className="tot-timing">
                                                    <ul>
                                                        <li>{rhours > 0 ? <div>Time : {rhours} Hours {rminutes} Minutes</div> : <div>Time : {rminutes} Minutes</div>}</li>
                                                        <li><div>No of Units : {modules[i]['number_of_children']}</div></li>
                                                    </ul> 
                                                </span>
                                            </div>
                                        </div>
                                        <div className="module-bottom">
                                            <div className="card-detailBack">
                                                <p dangerouslySetInnerHTML = {{__html: modules[i]['summary']}} style={{maxHeight: '165px', minHeight: '165px', overflow: 'hidden'}}></p>
                                            </div>
                                        </div>
                                        <h6 id={id} onClick={(id) => this.flipRevers(id)} style={{cursor: 'pointer'}}>View Less</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        // <div className="module-card">
                        //     <div className="top-bg">
                        //             <div className="module-img">
                        //                 <img src={modules[i]['icon_url']} alt="" />
                        //             </div>
                        //         </div>
                        //         <div className="module-inner">
                        //             <p>{modules[i]['type']} </p>
                        //             <h3 style={{minHeight: '45px'}}>
                        //                 <a href={modules[i]['url']} target="_blank">{modules[i]['title']}</a>
                        //             </h3>
                        //             <div className="rating-part">
                        //                 <span className="tot-timing">
                        //                     {rhours > 0 ? <div>{rhours} Hours {rminutes} Minutes</div> : <div>{rminutes} Minutes</div>}
                        //                 </span>
                        //             </div>
                        //             <ul className="tag-grp">
                        //                 {disprolestags}
                        //                 {dispLevelstags}
                        //             </ul>
                        //         </div>
                        //         <div className="module-bottom">
                        //             <div className="card-detail">
                        //                 <p dangerouslySetInnerHTML = {{__html: modules[i]['summary']}} style={{maxHeight: '100px', minHeight: '100px', overflow: 'hidden'}}></p>
                        //             </div>
                        //             <a href={modules[i]['url']} target="_blank" className="btnView">View More</a>
                        //         </div>
                        // </div>             
                    )
                }
            }
        }
        let displearningpath = [], l=0;
        if(['learningPath'].some(r => selectedTypes.includes(r))) {
            for(l in learningPaths) {
                var num = learningPaths[l]['duration_in_minutes'];
                var hours = (num / 60);
                var rhours = Math.floor(hours);
                var minutes = (hours - rhours) * 60;
                var rminutes = Math.round(minutes);

                const foundLevel = learningPaths[l]['levels'].some(r => selectedLevels.includes(r))
                const foundRole = learningPaths[l]['roles'].some(r => selectedRoles.includes(r))
                const foundProduct = learningPaths[l]['products'].some(r => selectedProducts.includes(r))

                var levelCount = learningPaths[l]['levels'].length
                var roleCount = learningPaths[l]['roles'].length
                var productCountTag = learningPaths[l]['products'].length
                var dispLevelstags = [], disprolestags = [], dispProducttags = []
                for(var countl=0; countl < levelCount; countl++) {
                    dispLevelstags.push(
                        <li className="tags" key={countl}>{learningPaths[l]['levels'][countl]}</li>
                    )
                }
                for(var countr=0; countr < roleCount; countr++) {
                    disprolestags.push(
                        <li className="tags" key={countr}>{learningPaths[l]['roles'][countr]}</li>
                    )
                }
                for(var countp=0; countp < productCountTag; countp++) {
                    dispProducttags.push(
                        <li className="tags" key={countp}>{learningPaths[l]['products'][countp]}</li>
                    )
                }

                if(foundLevel===true && foundRole===true && foundProduct===true) {
                    var Cid = "Card_" + learningPaths[l]['uid']
                    var id = learningPaths[l]['uid']
                    count++
                    displearningpath.push( 
                        <div className="card module-card" key={l} style={{maxHeight: '345px', minHeight: '345px', marginTop: '2%'}}>
                    <div className="flipM"> 
                        <div className="cardM" id={Cid}> 
                            <div className="faceM frontM" id={id}>
                            {/* <div className="faceM frontM" id={id} onClick={ (id) => this.flipClick(id)}> */}
                                {/* <div className="card module-card"> */}
                                    <div className="top-bg">
                                        <div className="module-img">
                                            <img src={learningPaths[l]['icon_url']} alt="" />
                                        </div>
                                    </div>
                                    <div className="module-inner">
                                        <p>{learningPaths[l]['type']} </p>
                                        <h3 style={{minHeight: '45px'}}>
                                            <a href={"/" + learningPaths[l]['uid']} >{learningPaths[l]['title']}</a>
                                        </h3>
                                        <div className="rating-part">
                                            <span className="tot-timing">
                                                {rhours > 0 ? <div>{rhours} Hours {rminutes} Minutes</div> : <div>{rminutes} Minutes</div>}
                                            </span>
                                        </div>
                                        <ul className="tag-grp">
                                            <li className="tags">{learningPaths[l]['products'][0]}</li>
                                            <li className="tags">{learningPaths[l]['roles'][0]}</li>
                                            <li className="tags">{learningPaths[l]['levels'][0]}</li>
                                        </ul>
                                    </div>
                                    <div className="module-bottom">
                                        <div className="card-detail">
                                            <p dangerouslySetInnerHTML = {{__html: learningPaths[l]['summary']}} style={{maxHeight: '47px', minHeight: '47px', overflow: 'hidden'}}></p>
                                        </div>
                                        <div className="cardReadMore"><h4 id={id} onClick={(id) => this.flipClick(id)} style={{cursor: 'pointer', marginBottom: '10px'}}>Read More...</h4></div>
                                    </div>
                                {/* </div> */}
                            </div> 
                            <div className="faceM backM" id={id}> 
                                <div className="top-bg">
                                    <div className="module-img">
                                        <img src={learningPaths[l]['icon_url']} alt="" />
                                    </div>
                                </div>
                                <div className="module-inner">
                                    {/* <ul className="tag-grp">
                                        <li className="tags">{learningPaths[l]['products'][0]}</li>
                                        <li className="tags">{learningPaths[l]['roles'][0]}</li>
                                        <li className="tags">{learningPaths[l]['levels'][0]}</li>
                                    </ul> */}
                                    <div className="rating-part">
                                        <span className="tot-timing">
                                            <ul>
                                                <li>{rhours > 0 ? <div>Time : {rhours} Hours {rminutes} Minutes</div> : <div>Time : {rminutes} Minutes</div>}</li>
                                                <li><div>No of Modules : {learningPaths[l]['number_of_children']}</div></li>
                                            </ul> 
                                        </span>
                                    </div>
                                </div>
                                <div className="module-bottom">
                                    <div className="card-detailBack">
                                        <p dangerouslySetInnerHTML = {{__html: learningPaths[l]['summary']}} style={{maxHeight: '165px', minHeight: '165px', overflow: 'hidden'}}></p>
                                    </div>
                                    </div>
                                <h6 id={id} onClick={(id) => this.flipRevers(id)} style={{cursor: 'pointer'}}>View Less</h6>
                            </div> 
                        </div> 
                    </div> 
                    </div>
                        // <div className="module-card" key={l}>
                        //     <div className="top-bg">
                        //             <div className="module-img">
                        //                 <img src={learningPaths[l]['icon_url']} alt="" />
                        //             </div>
                        //         </div>
                        //         <div className="module-inner">
                        //             <p>{learningPaths[l]['type']} </p>
                        //             <h3 style={{minHeight: '45px'}}>
                        //                 <a href={learningPaths[l]['url']} target="_blank">{learningPaths[l]['title']}</a>
                        //             </h3>
                        //             <div className="rating-part">
                        //                 <span className="tot-timing">
                        //                     {rhours > 0 ? <div>{rhours} Hours {rminutes} Minutes</div> : <div>{rminutes} Minutes</div>}
                        //                 </span>
                        //             </div>
                        //             <ul className="tag-grp">
                        //                 {disprolestags}
                        //                 {dispLevelstags}
                        //                 <li className="tags">{modules[i]['roles'][0]}</li>
                        //                 <li className="tags">{modules[i]['levels'][0]}</li>
                        //             </ul>
                        //         </div>
                        //         <div className="module-bottom">
                        //             <div className="card-detail">
                        //                 <p dangerouslySetInnerHTML = {{__html: learningPaths[l]['summary']}} style={{maxHeight: '100px', minHeight: '100px', overflow: 'hidden'}}></p>
                        //             </div>
                        //             <a href={learningPaths[l]['url']} target="_blank" className="btnView">View More</a>
                        //         </div>
                        // </div>             
                    )
                }
            }
        }
        let disprole = [], j
        const { roles } = this.state
        for(j in roles) {
            disprole.push(
                <li key={j}>
                    <input name="role" className="styled-checkbox" id={roles[j]['id']} type="checkbox" value={roles[j]['id']} title={roles[j]['name']} onChange={this.roleChange} />
                    <label htmlFor={roles[j]['id']}>{roles[j]['name']}</label>
                </li>
            )
        }

        let displevel = [], k
        const { levels } = this.state
        for(k in levels) {
            displevel.push(
                <li key={k}>
                    <input name="levels" className="styled-checkbox" id={levels[k]['id']} type="checkbox" value={levels[k]['id']} title={levels[k]['name']} onChange={this.levelChange} />
                    <label htmlFor={levels[k]['id']}>{levels[k]['name']}</label>
                </li>
            )
        }

        let dispProduct = [], product
        const { products } = this.state
        let productList = [], productCount = 0
        if(products.length > 0) {       
            for(product in products) {
                let dipsChild=[], child
                productList[productCount++] = products[product]['id']
                if(products[product]['children']){
                    let productChild = products[product]['children']
                    if(productChild.length > 0) {
                        for(child in productChild){
                            dipsChild.push(
                                <li key={child}>
                                    <input name="product" className="styled-checkbox" id={productChild[child]['id']} type="checkbox" value={productChild[child]['id']} title={productChild[child]['name']} onChange={this.productChange} />
                                    <label htmlFor={productChild[child]['id']}>{productChild[child]['name']}</label>
                                </li>
                            )
                            productList[productCount++] = productChild[child]['id']
                        }
                    }
                }
                dispProduct.push(
                    <li key={product}>
                        {/* <input name="products" className="styled-checkbox" id={products[product]['id']} type="checkbox" value={products[product]['id']} title={products[product]['name']} onChange={this.productChange} /> */}
                        <label htmlFor={products[product]['id']}>{products[product]['name']}</label>
                        <ul>
                            {dipsChild}
                        </ul>
                    </li>
                )
            }
        }

        return (
            <Fragment>
                {this.state.login ? 
                    <div>
                        <div className="inner-banner">
                            <div className="container">
                                <div className="inner-detail">
                                    <h3 className="h2">LearningPath / Modules</h3>
                                </div>
                            </div>
                        </div>
                        <div className="main-content">
                            <div className="browse-block">
                                <div className="container">
                            <div className="browse-wrapper">
                                <div className="sidebar filters-button-group">
                                    <h3 className="bottom-devider">Filter</h3>
                                    <ul>
                                        <li>
                                            <span onClick={this.showAll}>
                                                Show all
                                            </span>
                                        </li>
                                        <li>
                                            <span>Product</span>
                                            <ul className="unstyled centered">
                                                {dispProduct}
                                            </ul>
                                        </li>
                                        <li>
                                            <span>Role</span>
                                            <ul className="unstyled centered">
                                                {disprole}
                                            </ul>
                                        </li>
                                        <li>
                                            <span>Level</span>
                                            <ul className="unstyled centered">
                                                {displevel}
                                            </ul>
                                        </li>
                                        <li>
                                            <span>Type</span>
                                            <ul className="unstyled centered">
                                                <li>
                                                    <input className="styled-checkbox" type="checkbox" name="type" id='learningPath' value="learningPath" title="learningPath" onChange={this.typeChange} />
                                                    <label htmlFor="learningPath">Learning Path</label>
                                                </li>
                                                <li>
                                                    <input className="styled-checkbox" type="checkbox" name="type" id='module' value="module" title="module" onChange={this.typeChange} />
                                                    <label htmlFor="module">Module</label>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mainbar">
                                    {/* <div className="search-form">
                                        <form>
                                            <div className="form-group">
                                                <input type="search" placeholder="Search" />
                                                <input type="submit" value="" />
                                            </div>
                                        </form>
                                    </div> */}
                                    {this.state.count===-1 ? <div className="loader"></div> :
                                        <div>
                                        <div className="result-search">
                                            <span className="total--count">{count} results found</span>
                                        </div>
                                        <div className="blog-list-filter">
                                            {displearningpath}
                                            {dispmodule}
                                        </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                :
                <div>
                    <div className="inner-banner">
                        <div className="container">
                            <div className="inner-detail" style={{height: '180px'}}>
                                <center><h3 className="h2">Please Login for More Courses</h3></center>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        courses: state.courses.courses,
        authData: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCourses: () => dispatch(getCourses())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Courses)