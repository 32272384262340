import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux';

import Header from './Components/Layout/Header'
import Footer from './Components/Layout/Footer'
import Home from './Components/Home';
import Courses from './Components/Courses'
import NotFound from './Components/Layout/NotFound'

import { getCourses } from './Actions/courseActions'
import LearningPath from './Components/LearningPath'

import * as Msal from 'msal'
import { msalConfig, loginRequest} from './adalConfig'
const myMSALObj = new Msal.UserAgentApplication(msalConfig)

class App extends Component {

  state = {
    modules: null,
    learningPaths: null,
    levels: null,
    roles: null,
    products: null,

    login: false,
    userData: null
  }

  componentDidMount() {
    this.props.getCourses()
  }

  componentWillReceiveProps(props) {
    let abc = myMSALObj.getAccount()
    if(abc) {
      this.setState({
        login: true,
        userData: abc
      })
    } else {
      this.setState({
        login: false,
        userData: null
      })
    }
    this.setState({
      modules: props.courses.modules,
      learningPaths: props.courses.learningPaths
    })
  }

  render() {
    const courseProps = {
      modules: this.state.modules,
      learningPaths: this.state.learningPaths
    }
    const authProps = {
      login: this.state.login,
      userData: this.state.userData
    }
    return (
      <div id="wrapper">
          <div id='widescreen' className="main-container">
            <Header loginData={authProps} />
            <Router>
              <Switch>
                <Route exact path="/" render={(props) => <Home {...props} coursesData={courseProps} authData={authProps}/> } />
                <Route exact path="/courses" render={(props) => <Courses {...props} coursesData={courseProps} /> } />
                <Route path="/learn*" render={(props) => <LearningPath {...props} coursesData={courseProps.learningPaths} coursesDataModules={courseProps.modules} /> } />
                <Route path="/*" component={NotFound} />
              </Switch>
            </Router>
            <Footer />
          </div>
          <div id="smallscreen" className="main-container" style={{display: 'none'}}>
            <Header />
            <center >
              <h3 style={{ alignItems: 'center', justifyContent: 'center', minHeight: '200px', display: 'flex'}}>Sorry, This Website is under construction for your device resolution.<br />Please Visit it on Desktop.</h3>
            </center>
            <Footer />
          </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    courses: state.courses.courses
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCourses: () => dispatch(getCourses())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
