import React, { Component } from 'react';

class Footer extends Component {
  render() {
    var d = new Date();
    var y = d.getFullYear();
    return (
      <footer className="main-footer">
        <div className="foo-top">
          <div className="container">
            <p>CloudThat Technologies Pvt. Ltd.</p>
            <address>
              102, 4th B cross, 5th Block, Koramangala, Industrial Area,
              Bengaluru, Karnataka - 560095
            </address>
            <ul>
              <li>
                Sales: <a href="tel:918880002200">+91 8880002200</a>
              </li>
              <li>
                Office: <a href="tel:918041435641">+91 8041435641</a>
              </li>
              <li>
                Email:{' '}
                <a href="mailto:sales@cloudthat.in">sales@cloudthat.in</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="foo-bottom">
          <div className="container">
            <p>© 2012 - {y} CloudThat. All rights reserved.</p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
