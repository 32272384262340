const initState = {
    courses: null,
    checkedLevels: null,
    checkedRoles: null
}

const courseReducer = (state = initState, action) => {
    switch(action.type) {
        case "GET_COURSES_SUCCESS" :
            return {
                ...state,
                courses: action.response
            }
        default : 
            return state
    }
}

export default courseReducer