export const getCourses = () => {
    return dispatch => {
        fetch("https://docs.microsoft.com/api/learn/catalog/")
        .then(handleResponse)
        .then(
            response => {
                dispatch(success(response))
            },
            error => {
                dispatch(fail(error))
            }
        )
    }

    function success(response) {
        return {
            type: 'GET_COURSES_SUCCESS',
            response
        }
    }

    function fail(error) {
        return {
            type: 'GET_COURSES_FAIL',
            error
        }
    }
}

function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 404) {
          console.log("404: ",response)
          return response;
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }