const initState = {
    userData: '',
    login: false
}

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case "SIGNIN_SUCCESS" :
            return {
                ...state,
                login: true,
                userData: action.userData
            }
        default :
            return state
    }
}

export default authReducer