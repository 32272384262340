import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { ProgressBar, Step } from 'react-step-progress-bar'
import "react-step-progress-bar/styles.css"
import * as Msal from 'msal'
import { msalConfig, loginRequest} from '../adalConfig'

const myMSALObj = new Msal.UserAgentApplication(msalConfig)

class LearningPath extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Title: '',
            Icon: 'assets/images/search-img.png',
            Time: '',
            NoOfModules: '',
            Tags: [],
            Summary: '',
            ModuleDisplay: ''
        }
    }

    componentDidMount() {
        if(myMSALObj.getAccount()) {
            this.setState({
                login: true,
                userData: myMSALObj.getAccount()
            })
        }
    }

    componentWillReceiveProps(props) {
        if(myMSALObj.getAccount()) {
            this.setState({
                login: true,
                userData: myMSALObj.getAccount()
            })
        }
        let url = props.match.url.slice(1)
        let learningPathDetails = props.coursesData
        let moduleDetails = props.coursesDataModules
        this.findLearningPath(url, learningPathDetails, moduleDetails)
    }

    findLearningPath(url, learningPathDetails, moduleDetails) {
        let i, lpDetails
        for(i in learningPathDetails) {
            if(learningPathDetails[i].uid === url) {
                lpDetails = learningPathDetails[i]
                this.getLearningPathDetails(lpDetails, moduleDetails)
            }
        }
    }

    getLearningPathDetails(lpDetails, moduleDetails) {
        var num = lpDetails['duration_in_minutes'];
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        let time
        if(rhours > 0) {
            time = "" + rhours + " Hours " + rminutes + " Minutes"
        } else {
            time = "" + rminutes + " Minutes"
        }
        let tags = [], i, key = 0
        for(i = 0; i < lpDetails.products.length; i++, key++){
            tags.push(
                <li key={key} className="tags">{lpDetails.products[i]}</li>
            )
        }
        for(i = 0; i < lpDetails.roles.length; i++, key++){
            tags.push(
                <li key={key} className="tags">{lpDetails.roles[i]}</li>
            )
        }
        for(i = 0; i < lpDetails.levels.length; i++, key++){
            tags.push(
                <li key={key} className="tags">{lpDetails.levels[i]}</li>
            )
        }
        let modules = lpDetails.modules
        this.getModules(modules, moduleDetails)
        this.setState({
            Title : lpDetails.title,
            Icon: lpDetails.icon_url,
            Time : time,
            NoOfModules : "    " + lpDetails["number_of_children"] + " Modules",
            Tags: tags,
            Summary : lpDetails.summary
        })
    }

    getModules(modules, moduleDetails) {
        let i, j, modulesDisp = []
        for(i in modules) {
            for(j in moduleDetails) {
                if(moduleDetails[j]['uid'] === modules[i]) {
                    var num = moduleDetails[j]['duration_in_minutes'];
                    var hours = (num / 60);
                    var rhours = Math.floor(hours);
                    var minutes = (hours - rhours) * 60;
                    var rminutes = Math.round(minutes);
                    let time
                    if(rhours > 0) {
                        time = "" + rhours + " Hours " + rminutes + " Minutes"
                    } else {
                        time = "" + rminutes + " Minutes"
                    }
                    modulesDisp.push(
                        <div key={j} className="module-card" style={{maxHeight: '220px', minHeight: '220px'}}>
                            <div className="top-bg">
                                <div className="module-img">
                                    <img src={moduleDetails[j]['icon_url']} alt="" />
                                </div>
                            </div>
                            <div className="module-inner">
                                <h3 style={{minHeight: '50px'}}><a href={moduleDetails[j]['url']} target="_blank">{moduleDetails[j]['title']}</a></h3>
                                <div className="rating-part">
                                    <span className="tot-timing">{time}</span>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
        this.setState({
            ModuleDisplay: modulesDisp
        })
    }

    render() {
        const { Title, Icon, Time, NoOfModules, Tags, Summary, ModuleDisplay } = this.state
        return (
            <Fragment>
                {this.state.login ? 
                    <div>
                        <div className="inner-banner">
                            <div className="container">
                                <div className="learningPath">
                                    <div className="icon" style={{maxHeight: "1%"}}>
                                        <img src={Icon} alt="Icon"/>
                                    </div>
                                    <div className="title">
                                        <div><h4>{Title}</h4></div>
                                        <ul className="tag-grp">
                                            <li style={{listStyle: 'none', display: 'inline-block'}}>{Time}</li>
                                            <li style={{display: 'inline-block', marginLeft: "5%"}}>   {NoOfModules}</li>
                                        </ul>
                                        <ul className="tag-grp">
                                            {Tags}
                                        </ul>
                                        <ul className="tag-grp">
                                            <p dangerouslySetInnerHTML = {{__html: Summary}} ></p>
                                        </ul>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="main-content">
                            <div className="browse-block">
                                <div className="container">
                                    <div className="browse-wrapper">
                                        <div className="mainbar" style={{width: '80%', margin: '0% 10% 0% 10%'}}>
                                            <h5>Modules in this learning path</h5>
                                            <br />
                                            <div className="blog-list-filter">
                                                {ModuleDisplay}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    <div className="inner-banner">
                        <div className="container">
                            <div className="inner-detail" style={{height: '180px'}}>
                                <center><h3 className="h2">Please Login for More Details</h3></center>
                            </div>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        authData: state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getCourses: () => dispatch(getCourses())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (LearningPath)
