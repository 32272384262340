import React, { Component, Fragment } from 'react'
import { getCourses } from '../Actions/courseActions'
import { connect } from 'react-redux'

import authentication from 'react-azure-b2c';

class Home extends Component {

    state = {
        modules: '',
        learningpaths: ''
    }

    componentWillReceiveProps = (props) => {
        const { modules, learningPaths } = props.courses

        this.setState({
            modules: modules,
            learningpaths: learningPaths
        })
    }

    flipClick = (e) => {
        var eid = "Card_" + e.target.id
        var item = document.getElementById(eid)
        item.classList.add('flipped')
    }

    flipRevers = (e) => {
        var eid = "Card_" + e.target.id
        var item = document.getElementById(eid)
        item.classList.remove('flipped')
    }

    render() {
        let displearningpath = [], j
        const { learningpaths } = this.state

        for(j in learningpaths) {
            var num = learningpaths[j]['duration_in_minutes'];
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if(j < 4) {
                var Cid = "Card_" + learningpaths[j]['uid']
                var id = learningpaths[j]['uid']
                displearningpath.push(
                    // <div className="card module-card" key={j}>
                    //     <div className="frontSide1">
                    //         <div className="top-bg">
                    //             <div className="module-img">
                    //                 <img src={learningpaths[j]['icon_url']} alt="" />
                    //             </div>
                    //         </div>
                    //         <div className="module-inner">
                    //             <p>{learningpaths[j]['type']} </p>
                    //             <h3 style={{minHeight: '45px'}}>
                    //                 <a href={learningpaths[j]['url']} target="_blank">{learningpaths[j]['title']}</a>
                    //             </h3>
                    //             <div className="rating-part">
                    //                 <span className="tot-timing">
                    //                     {rhours > 0 ? <div>{rhours} Hours {rminutes} Minutes</div> : <div>{rminutes} Minutes</div>}
                    //                 </span>
                    //             </div>
                    //             <ul className="tag-grp">
                    //                 <li className="tags">{learningpaths[j]['products'][0]}</li>
                    //                 <li className="tags">{learningpaths[j]['roles'][0]}</li>
                    //                 <li className="tags">{learningpaths[j]['levels'][0]}</li>
                    //             </ul>
                    //         </div>
                    //         <div className="module-bottom">
                    //             <div className="card-detail">
                    //                 <p dangerouslySetInnerHTML = {{__html: learningpaths[j]['summary']}} style={{maxHeight: '100px', minHeight: '100px', overflow: 'hidden'}}></p>
                    //             </div>
                    //             <a href={learningpaths[j]['url']} target="_blank" className="btnView">View More</a>
                    //         </div>
                    //     </div> 
                    //     <div className="backSide1">
                    //         <div className="top-bg">
                    //             <div className="module-img">
                    //                 <img src={learningpaths[j]['icon_url']} alt="" />
                    //             </div>
                    //         </div>
                    //         <div className="module-inner">
                    //             <p>{learningpaths[j]['type']} </p>
                    //             <h3 style={{minHeight: '45px'}}>
                    //                 <a href={learningpaths[j]['url']} target="_blank">{learningpaths[j]['title']}</a>
                    //             </h3>
                    //             <div className="rating-part">
                    //                 <span className="tot-timing">
                    //                     {rhours > 0 ? <div>{rhours} Hours {rminutes} Minutes</div> : <div>{rminutes} Minutes</div>}
                    //                 </span>
                    //             </div>
                    //             <ul className="tag-grp">
                    //                 <li className="tags">{learningpaths[j]['products'][0]}</li>
                    //                 <li className="tags">{learningpaths[j]['roles'][0]}</li>
                    //                 <li className="tags">{learningpaths[j]['levels'][0]}</li>
                    //             </ul>
                    //         </div>
                    //         <div className="module-bottom">
                    //         <div className="card-detail">
                    //             <p dangerouslySetInnerHTML = {{__html: learningpaths[j]['summary']}} style={{maxHeight: '100px', minHeight: '100px', overflow: 'hidden'}}></p>
                    //         </div>
                    //         <a href={learningpaths[j]['url']} target="_blank" className="btnView">View More</a>
                    //     </div>
                    //     </div>  
                    // </div>
                    <div className="card module-card" key={j} style={{maxHeight: '345px', minHeight: '345px', marginTop: '2%'}}>
                    <div className="flipM"> 
                        <div className="cardM" id={Cid}> 
                            <div className="faceM frontM" id={id}>
                            {/* <div className="faceM frontM" id={id} onClick={ (id) => this.flipClick(id)}> */}
                                {/* <div className="card module-card"> */}
                                    <div className="top-bg">
                                        <div className="module-img">
                                            <img src={learningpaths[j]['icon_url']} alt="" />
                                        </div>
                                    </div>
                                    <div className="module-inner">
                                        <p>{learningpaths[j]['type']} </p>
                                        <h3 style={{minHeight: '45px'}}>
                                            <a href={"/" + learningpaths[j]['uid']} target="_blank">{learningpaths[j]['title']}</a>
                                            {/* <a href={"/lp-" + learningpaths[j]['uid']} target="_blank">{learningpaths[j]['title']}</a> */}
                                        </h3>
                                        <div className="rating-part">
                                            <span className="tot-timing">
                                                {rhours > 0 ? <div>{rhours} Hours {rminutes} Minutes</div> : <div>{rminutes} Minutes</div>}
                                            </span>
                                        </div>
                                        <ul className="tag-grp">
                                            <li className="tags">{learningpaths[j]['products'][0]}</li>
                                            <li className="tags">{learningpaths[j]['roles'][0]}</li>
                                            <li className="tags">{learningpaths[j]['levels'][0]}</li>
                                        </ul>
                                    </div>
                                    <div className="module-bottom">
                                        <div className="card-detail">
                                            <p dangerouslySetInnerHTML = {{__html: learningpaths[j]['summary']}} style={{maxHeight: '47px', minHeight: '47px', overflow: 'hidden'}}></p>
                                        </div>
                                        <div className="cardReadMore"><h4 id={id} onClick={(id) => this.flipClick(id)} style={{cursor: 'pointer', marginBottom: '10px'}}>Read More...</h4></div>
                                    </div>
                                {/* </div> */}
                            </div> 
                            <div className="faceM backM" id={id}> 
                                <div className="top-bg">
                                    <div className="module-img">
                                        <img src={learningpaths[j]['icon_url']} alt="" />
                                    </div>
                                </div>
                                <div className="module-inner">
                                    {/* <ul className="tag-grp">
                                        <li className="tags">{learningpaths[j]['products'][0]}</li>
                                        <li className="tags">{learningpaths[j]['roles'][0]}</li>
                                        <li className="tags">{learningpaths[j]['levels'][0]}</li>
                                    </ul> */}
                                    <div className="rating-part">
                                        <span className="tot-timing">
                                            <ul>
                                                <li>{rhours > 0 ? <div>Time : {rhours} Hours {rminutes} Minutes</div> : <div>Time : {rminutes} Minutes</div>}</li>
                                                <li><div>No of Modules : {learningpaths[j]['number_of_children']}</div></li>
                                            </ul> 
                                        </span>
                                    </div>
                                </div>
                                <div className="module-bottom">
                                    <div className="card-detailBack">
                                        <p dangerouslySetInnerHTML = {{__html: learningpaths[j]['summary']}} style={{maxHeight: '165px', minHeight: '165px', overflow: 'hidden'}}></p>
                                    </div>
                                    </div>
                                <h6 id={id} onClick={(id) => this.flipRevers(id)} style={{cursor: 'pointer'}}>View Less</h6>
                            </div> 
                        </div> 
                    </div> 
                    </div>     
                )
            }
        }

        return (
            <Fragment>
                <section className="hero-section" style={{backgroundImage: "URL(assets/images/banner_img.png)" }}>
                    <div className="container">
                        <div className="banner-wrapper">
                            <div className="banner-detail">
                                <h1><span>Welcome To</span>CloudThat Courses</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="main-content">
                    <section className="media-card-block">
                        <div className="container">
                            <div className="media-card">
                                <div className="single-card">
                                    <div className="card-img">
                                        <img src="assets/images/learningpath.png" alt="" />
                                    </div>
                                    <div className="card-detail">
                                        <h6>LEARNING PATHS</h6>
                                        <h4><a href="/courses">Learn on your own schedule</a></h4>
                                        <p>Explore a topic in-depth through guided paths or learn how to accomplish a specific task through individual modules.</p>
                                        <a href="/courses" className="read-more">Browse all learning options</a>
                                    </div>
                                </div>
                                <div className="single-card">
                                    <div className="card-img">
                                        <img src="assets/images/certifications.png" alt="" />
                                    </div>
                                    <div className="card-detail">
                                        <h6>CERTIFICATIONS</h6>
                                        <h4><a href="#">Become Microsoft Certified</a></h4>
                                        <p>Jump-start your career and demonstrate your achievements through industry-recognized Microsoft certifications.</p>
                                        <a href="#" className="read-more">Explore Certifications</a>
                                    </div>
                                </div>
                                <div className="single-card">
                                    <div className="card-img">
                                        <img src="assets/images/classroom.jpg" alt="" />
                                    </div>
                                    <div className="card-detail">
                                        <h6>Classroom Training</h6>
                                        <h4><a href="https://cloudthat.in" target="_blank">Get Intensive Hands-on training</a></h4>
                                        <p>Get an opportunity to train from experienced and qualified trainers, mainly focusing on Hands-on, Use cases and real time examples.</p>
                                        <a href="https://cloudthat.in" target="_blank" className="read-more">Explore Classroom Training</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="popular-module-block">
                        <div className="container">
                            <h2 className="bottom-devider">Popular Learning Paths and Modules</h2>
                            <div className="module-card-wrapper">
                                {displearningpath}
                            </div>
                            <a href="/courses" className="btn arriw-right">Explore More Learning Path</a>
                        </div>  
                    </section>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        courses: state.courses.courses
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCourses: () => dispatch(getCourses())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Home)