export const msalConfig = {
    auth: {
      clientId: "ae57e688-5c4d-49a5-b4c5-df7666570273",
      authority: "https://login.microsoftonline.com/common",
      redirectUri: "https://freecloudcourses.com",
      postLogoutRedirectUri: "https://freecloudcourses.com"
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new
    }
  };  
  
  // Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
  };