import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { signInAction, signOutAction } from '../../Actions/auth'

import * as Msal from 'msal'
import { msalConfig, loginRequest} from '../../adalConfig'

const myMSALObj = new Msal.UserAgentApplication(msalConfig)

class Header extends Component {
    state = {
        login: false,
        userData: null
    }


    componentWillReceiveProps = (props) => {
        if(props.authData || props.loginData) 
            if(props.authData.login || props.loginData.login) {
                this.setState({
                    login: true,
                    userData: myMSALObj.getAccount()
                })
            } else {    
                this.setState({
                    login: false,
                    userData: ''
                })
            } 
    }

    signIn = () => {
        this.props.signInAction()
    }

    signOut = () => {
        this.props.signOutAction(myMSALObj)
    }

    render() {
        return (
            <Fragment>
                <div id="mobilenav" className="hidden-md-up">
                    <div className="nav-backdrop"></div>
                    <div id="menu" link-clickable="false">
                        <button className="hamburger close">
                            <span className="wrap">
                                <span className="line"></span>
                                <span className="line"></span>
                            </span>
                        </button>
                        <div className="menu-outer">
                            <ul role="menu">
                                <li className="active"><a href="/courses">Learning Paths</a></li>
                                <li><a href="#">Certifications</a></li>
                                <li><a href="#">FAQ & Help</a></li>
                                {!this.state.login ? 
                                    <li><a><div onClick={this.signIn}>Log In</div></a></li>
                                    :
                                    <li><a><div onClick={this.signOut}>Log Out</div></a></li>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <header className="main-header">
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="header-left">
                                <a href="/" className="logo"><img src="assets/images/logo.png" alt="" /></a>
                                <div className="navigation">
                                    <ul>
                                        <li className="active"><a href="/courses">Learning Paths</a></li>
                                        <li><a href="#">Certifications</a></li>
                                        <li><a href="#">FAQ & Help</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="header-right">
                                {this.state.login == false ? 
                                    <div className="form-group">
                                        {/* <span>{this.state.userData}</span> */}
                                        <button className="btnLogout" onClick={this.signIn}>Log In</button>
                                    </div>
                                    :
                                    <div className="form-group">
                                            <button className="nameplate">Welcome, {this.state.userData.name}</button>
                                            <button className="btnLogout" onClick={this.signOut}>Log Out</button>
                                    </div>
                                }
                                <button className="hamburger">
                                    <span className="wrap">
                                        <span className="line"></span>
                                        <span className="line"></span>
                                        <span className="line"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </header>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return{
        authData : state.auth
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signInAction: () => dispatch(signInAction()),
        signOutAction: (myMSALObj) => dispatch(signOutAction(myMSALObj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Header)