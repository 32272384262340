import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { createStore, compose, applyMiddleware } from 'redux'
import rootReducer from './Reducers'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'

import * as serviceWorker from './serviceWorker';

const persistConfig = {
    key: "root",
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const tempstore = createStore(persistedReducer, compose(applyMiddleware(thunk)))

ReactDOM.render(
    <Provider store={tempstore}>
        <App />
    </Provider>,
    document.getElementById('root')
)

serviceWorker.unregister();
